// import * as bootstrap from 'bootstrap'
// @import "../../../node_modules/bootstrap/scss/bootstrap.scss";


// ***************************************************************************************************************************************
// ********************************************PARALLAX************************************************************************************
// ***************************************************************************************************************************************

window.addEventListener('scroll', function (e) {
        var nDefillement = window.pageYOffset;
        const oFond = document.querySelector('.cParallaxe1');
        oFond.style.backgroundPosition = "0 "+ -(nDefillement * 0.2) + 'px';
    });
    
    window.addEventListener('scroll', function (e) {
        var nDefillement = window.pageYOffset;
        const oFond = document.querySelector('.cParallaxe2');
        oFond.style.backgroundPosition = "0 "+ -(nDefillement * 0.2) + 'px';
        console.log("COUCOU DGFHJQTRJHQTRJQRTQR");
    });
    

// window.addEventListener('scroll', function (e) {
    //     var nDefillement = window.pageYOffset;
    //     const oFond = document.querySelector('.cParallaxe3');
    //     oFond.style.backgroundPosition = "0 "+ -(nDefillement * 0.2) + 'px';
    // });
    
    console.log("COUCOU ici");
    
    
